import './style.css';
import Pictures from "./pictures"
import Api  from "../../includes/api";
import { useState, useEffect, useRef}   from "react";

export default (props) => {
   
    const [pictures,    setPictures]    = useState([]);
    const [password,    setPassword]    = useState(null);
    const [is_auth,     setIsAuth]      = useState(false);
  
  
    useEffect(() => {        
       
        isAuth();
    
    }, []);


    const isAuth = async (id) => {
                       
        const auth = await Api.get("is-auth");  
        
        if(!auth.error && auth.content === true){
            
            setIsAuth(true);
        }
    };
    
    
    const auth = async (id) => {
                       
        const auth = await Api.post("auth", {key:password});   
        
        if(!auth.error){
            
            localStorage.setItem("auth", auth.content);
            
            window.location = "/admin";
        }
    };
    
    
    const drawDashboard = () => {
        
        return <section><h1>Images</h1></section>
    }
    
    return (                 
        <div id="admin"> 
            {
                !is_auth ?
                    <div id="auth-panel">
                        <label>
                        <div class="title">Password</div>
                            <input type="password" onChange={(e) => setPassword(e.target.value)}/>
                        </label>                
                        <button class="blue-button" onClick={auth}>Continuer</button>
                    </div>
                    :                                                                  
                    <Pictures />                  
            }
        </div>                       
    );
} 