import './style.css';
import Pictures     from "../pictures/pictures";
import Brands       from "../brands/brands";
import Rental       from "../rental/rental";
import VirtualVisit from "../virtual-visit/virtual-visit";
import GoogleMaps   from "../google-maps/google-maps";

export default (props) => {
   
    return (    
        <>
        <div id="pictures-container">            
            <Pictures />          
        </div>  
        <VirtualVisit />
        <Brands />
        <Rental />
        <GoogleMaps />
        </>
    );
} 