import './style.css';
import Api      from "../../includes/api";
import Loader   from "../../elements/loader/loader";
import Menu     from "./menu";
import { useState, useEffect, useRef}   from "react";

export default (props) => {
   
    const [prices,    setPrices]  = useState({});

 
    useEffect(() => {
        
        loadPrices();
        
    }, []);
    
    
    const loadPrices = async () => {

        const prices = await Api.get("rental");  
        
        setPrices(prices.content);
    };


    const updatePrice = (category, days, price) => {
                
        const updated_prices = {...prices};
        
        if (!updated_prices[category]) {
            
            updated_prices[category] = {};
        }

        if (!updated_prices[category][days]) {
            
            updated_prices[category][days] = 0;
        }

        updated_prices[category][days] = price;
        
        setPrices(updated_prices);
    };

        
    const save = async(id) => {
        
        console.log(prices);
        const pictures = await Api.post("rental/save", {prices:prices});  
      
    };
    
    
    const drawInput= (category, days) => {
        
        const current_value = prices?.[category]?.[days] ?? "";
        
        return <input 
            type        ="text" 
            class       = "price" 
            value       = {current_value} 
            onChange    = {(e) => updatePrice(category, days, e.target.value)}
        />
    };
    
    
    return ( 
        <div id="admin">
            <section>
            <Menu />
                <div id="admin-rental">         
                    <table>
                    <tr>
                        <th></th>
                        <th>1/2d</th>
                        <th>1d</th>
                        <th>2d</th>
                        <th>3d</th>
                        <th>4d</th>
                        <th>5d</th>
                        <th>6d</th>
                        <th>7d</th>
                        <th>8d</th>
                        <th>9d</th>
                        <th>10d</th>
                        <th>11d</th>
                        <th>12d</th>
                        <th>13d</th>
                    </tr>
                    <tr>
                        <td colspan="15" class="category">Snowboard/Ski</td>
                    </tr>
                    <tr>
                        <td class="title">Premium</td>
                        <td>{drawInput("premium", "1/2")}</td>
                        <td>{drawInput("premium", "1")}</td>
                        <td>{drawInput("premium", "2")}</td>
                        <td>{drawInput("premium", "3")}</td>
                        <td>{drawInput("premium", "4")}</td>
                        <td>{drawInput("premium", "5")}</td>
                        <td>{drawInput("premium", "6")}</td>
                        <td>{drawInput("premium", "7")}</td>
                        <td>{drawInput("premium", "8")}</td>
                        <td>{drawInput("premium", "9")}</td>
                        <td>{drawInput("premium", "10")}</td>
                        <td>{drawInput("premium", "11")}</td>
                        <td>{drawInput("premium", "12")}</td>
                        <td>{drawInput("premium", "13")}</td>
                    </tr>
                    <tr>
                        <td class="title">Performance</td>
                        <td>{drawInput("performance", "1/2")}</td>
                        <td>{drawInput("performance", "1")}</td>
                        <td>{drawInput("performance", "2")}</td>
                        <td>{drawInput("performance", "3")}</td>
                        <td>{drawInput("performance", "4")}</td>
                        <td>{drawInput("performance", "5")}</td>
                        <td>{drawInput("performance", "6")}</td>
                        <td>{drawInput("performance", "7")}</td>
                        <td>{drawInput("performance", "8")}</td>
                        <td>{drawInput("performance", "9")}</td>
                        <td>{drawInput("performance", "10")}</td>
                        <td>{drawInput("performance", "11")}</td>
                        <td>{drawInput("performance", "12")}</td>
                        <td>{drawInput("performance", "13")}</td>
                    </tr>
                    <tr>
                        <td class="title">Evolution</td>
                        <td>{drawInput("evolution", "1/2")}</td>
                        <td>{drawInput("evolution", "1")}</td>
                        <td>{drawInput("evolution", "2")}</td>
                        <td>{drawInput("evolution", "3")}</td>
                        <td>{drawInput("evolution", "4")}</td>
                        <td>{drawInput("evolution", "5")}</td>
                        <td>{drawInput("evolution", "6")}</td>
                        <td>{drawInput("evolution", "7")}</td>
                        <td>{drawInput("evolution", "8")}</td>
                        <td>{drawInput("evolution", "9")}</td>
                        <td>{drawInput("evolution", "10")}</td>
                        <td>{drawInput("evolution", "11")}</td>
                        <td>{drawInput("evolution", "12")}</td>
                        <td>{drawInput("evolution", "13")}</td>
                    </tr>
                    <tr>
                        <td class="title">Champion</td>
                        <td>{drawInput("champion", "1/2")}</td>
                        <td>{drawInput("champion", "1")}</td>
                        <td>{drawInput("champion", "2")}</td>
                        <td>{drawInput("champion", "3")}</td>
                        <td>{drawInput("champion", "4")}</td>
                        <td>{drawInput("champion", "5")}</td>
                        <td>{drawInput("champion", "6")}</td>
                        <td>{drawInput("champion", "7")}</td>
                        <td>{drawInput("champion", "8")}</td>
                        <td>{drawInput("champion", "9")}</td>
                        <td>{drawInput("champion", "10")}</td>
                        <td>{drawInput("champion", "11")}</td>
                        <td>{drawInput("champion", "12")}</td>
                        <td>{drawInput("champion", "13")}</td>
                    </tr>
                    <tr>
                        <td class="title">Kids</td>
                        <td>{drawInput("kids", "1/2")}</td>
                        <td>{drawInput("kids", "1")}</td>
                        <td>{drawInput("kids", "2")}</td>
                        <td>{drawInput("kids", "3")}</td>
                        <td>{drawInput("kids", "4")}</td>
                        <td>{drawInput("kids", "5")}</td>
                        <td>{drawInput("kids", "6")}</td>
                        <td>{drawInput("kids", "7")}</td>
                        <td>{drawInput("kids", "8")}</td>
                        <td>{drawInput("kids", "9")}</td>
                        <td>{drawInput("kids", "10")}</td>
                        <td>{drawInput("kids", "11")}</td>
                        <td>{drawInput("kids", "12")}</td>
                        <td>{drawInput("kids", "13")}</td>
                    </tr>
                     <tr>
                        <td colspan="15" class="category">Boots</td>
                    </tr>
                    <tr>
                        <td class="title">Premium</td>
                        <td>{drawInput("boots_premium", "1/2")}</td>
                        <td>{drawInput("boots_premium", "1")}</td>
                        <td>{drawInput("boots_premium", "2")}</td>
                        <td>{drawInput("boots_premium", "3")}</td>
                        <td>{drawInput("boots_premium", "4")}</td>
                        <td>{drawInput("boots_premium", "5")}</td>
                        <td>{drawInput("boots_premium", "6")}</td>
                        <td>{drawInput("boots_premium", "7")}</td>
                        <td>{drawInput("boots_premium", "8")}</td>
                        <td>{drawInput("boots_premium", "9")}</td>
                        <td>{drawInput("boots_premium", "10")}</td>
                        <td>{drawInput("boots_premium", "11")}</td>
                        <td>{drawInput("boots_premium", "12")}</td>
                        <td>{drawInput("boots_premium", "13")}</td>
                    </tr>
                    <tr>
                        <td class="title">Evolution</td>
                        <td>{drawInput("boots_evolution", "1/2")}</td>
                        <td>{drawInput("boots_evolution", "1")}</td>
                        <td>{drawInput("boots_evolution", "2")}</td>
                        <td>{drawInput("boots_evolution", "3")}</td>
                        <td>{drawInput("boots_evolution", "4")}</td>
                        <td>{drawInput("boots_evolution", "5")}</td>
                        <td>{drawInput("boots_evolution", "6")}</td>
                        <td>{drawInput("boots_evolution", "7")}</td>
                        <td>{drawInput("boots_evolution", "8")}</td>
                        <td>{drawInput("boots_evolution", "9")}</td>
                        <td>{drawInput("boots_evolution", "10")}</td>
                        <td>{drawInput("boots_evolution", "11")}</td>
                        <td>{drawInput("boots_evolution", "12")}</td>
                        <td>{drawInput("boots_evolution", "13")}</td>
                    </tr>
                    <tr>
                        <td class="title">Kids</td>
                        <td>{drawInput("boots_kids", "1/2")}</td>
                        <td>{drawInput("boots_kids", "1")}</td>
                        <td>{drawInput("boots_kids", "2")}</td>
                        <td>{drawInput("boots_kids", "3")}</td>
                        <td>{drawInput("boots_kids", "4")}</td>
                        <td>{drawInput("boots_kids", "5")}</td>
                        <td>{drawInput("boots_kids", "6")}</td>
                        <td>{drawInput("boots_kids", "7")}</td>
                        <td>{drawInput("boots_kids", "8")}</td>
                        <td>{drawInput("boots_kids", "9")}</td>
                        <td>{drawInput("boots_kids", "10")}</td>
                        <td>{drawInput("boots_kids", "11")}</td>
                        <td>{drawInput("boots_kids", "12")}</td>
                        <td>{drawInput("boots_kids", "13")}</td>
                    </tr>
                    
                    <tr>
                        <td colspan="15" class="category">Packs</td>
                    </tr>
                    <tr>
                        <td class="title">Pack performance</td>
                        <td>{drawInput("pack_performance", "1/2")}</td>
                        <td>{drawInput("pack_performance", "1")}</td>
                        <td>{drawInput("pack_performance", "2")}</td>
                        <td>{drawInput("pack_performance", "3")}</td>
                        <td>{drawInput("pack_performance", "4")}</td>
                        <td>{drawInput("pack_performance", "5")}</td>
                        <td>{drawInput("pack_performance", "6")}</td>
                        <td>{drawInput("pack_performance", "7")}</td>
                        <td>{drawInput("pack_performance", "8")}</td>
                        <td>{drawInput("pack_performance", "9")}</td>
                        <td>{drawInput("pack_performance", "10")}</td>
                        <td>{drawInput("pack_performance", "11")}</td>
                        <td>{drawInput("pack_performance", "12")}</td>
                        <td>{drawInput("pack_performance", "13")}</td>
                    </tr>
                    <tr>
                        <td class="title">Pack champion</td>
                        <td>{drawInput("pack_champion", "1/2")}</td>
                        <td>{drawInput("pack_champion", "1")}</td>
                        <td>{drawInput("pack_champion", "2")}</td>
                        <td>{drawInput("pack_champion", "3")}</td>
                        <td>{drawInput("pack_champion", "4")}</td>
                        <td>{drawInput("pack_champion", "5")}</td>
                        <td>{drawInput("pack_champion", "6")}</td>
                        <td>{drawInput("pack_champion", "7")}</td>
                        <td>{drawInput("pack_champion", "8")}</td>
                        <td>{drawInput("pack_champion", "9")}</td>
                        <td>{drawInput("pack_champion", "10")}</td>
                        <td>{drawInput("pack_champion", "11")}</td>
                        <td>{drawInput("pack_champion", "12")}</td>
                        <td>{drawInput("pack_champion", "13")}</td>
                    </tr>
                    <tr>
                        <td class="title">Pack kids</td>
                        <td>{drawInput("pack_kids", "1/2")}</td>
                        <td>{drawInput("pack_kids", "1")}</td>
                        <td>{drawInput("pack_kids", "2")}</td>
                        <td>{drawInput("pack_kids", "3")}</td>
                        <td>{drawInput("pack_kids", "4")}</td>
                        <td>{drawInput("pack_kids", "5")}</td>
                        <td>{drawInput("pack_kids", "6")}</td>
                        <td>{drawInput("pack_kids", "7")}</td>
                        <td>{drawInput("pack_kids", "8")}</td>
                        <td>{drawInput("pack_kids", "9")}</td>
                        <td>{drawInput("pack_kids", "10")}</td>
                        <td>{drawInput("pack_kids", "11")}</td>
                        <td>{drawInput("pack_kids", "12")}</td>
                        <td>{drawInput("pack_kids", "13")}</td>
                    </tr>
                    
                    
                    <tr>
                        <td colspan="15" class="category"></td>
                    </tr>
                    <tr>
                        <td class="title">Step on</td>
                        <td>{drawInput("stepon", "1/2")}</td>
                        <td>{drawInput("stepon", "1")}</td>
                        <td>{drawInput("stepon", "2")}</td>
                        <td>{drawInput("stepon", "3")}</td>
                        <td>{drawInput("stepon", "4")}</td>
                        <td>{drawInput("stepon", "5")}</td>
                        <td>{drawInput("stepon", "6")}</td>
                        <td>{drawInput("stepon", "7")}</td>
                        <td>{drawInput("stepon", "8")}</td>
                        <td>{drawInput("stepon", "9")}</td>
                        <td>{drawInput("stepon", "10")}</td>
                        <td>{drawInput("stepon", "11")}</td>
                        <td>{drawInput("stepon", "12")}</td>
                        <td>{drawInput("stepon", "13")}</td>
                    </tr>
                    <tr>
                        <td colspan="15" class="category"></td>
                    </tr>
                    <tr>
                        <td class="title">Casque/Bâtons</td>
                        <td>{drawInput("helmet_poles", "1/2")}</td>
                        <td>{drawInput("helmet_poles", "1")}</td>
                        <td>{drawInput("helmet_poles", "2")}</td>
                        <td>{drawInput("helmet_poles", "3")}</td>
                        <td>{drawInput("helmet_poles", "4")}</td>
                        <td>{drawInput("helmet_poles", "5")}</td>
                        <td>{drawInput("helmet_poles", "6")}</td>
                        <td>{drawInput("helmet_poles", "7")}</td>
                        <td>{drawInput("helmet_poles", "8")}</td>
                        <td>{drawInput("helmet_poles", "9")}</td>
                        <td>{drawInput("helmet_poles", "10")}</td>
                        <td>{drawInput("helmet_poles", "11")}</td>
                        <td>{drawInput("helmet_poles", "12")}</td>
                        <td>{drawInput("helmet_poles", "13")}</td>
                    </tr>
                    </table>
                    <br/>
                    <br/>
                    <button class="blue-button" onClick={save}>Enregistrer</button>
                </div>
            </section>
        </div>
    );
} 