import './style.css';
import Api  from "../../includes/api";
import { useState, useEffect, useRef}   from "react";

export default (props) => {
   
   //<div id="map-link"><img src="pics/view-on-google-maps.png" /></div>
    return (    
       
            <div id="virtual-visit">        
                <h1 class="h1-with-icon">                                        
                    VISITE VIRTUELLE DU SHOP                     
                </h1>
                <div id="icon-360">
                    <a href="https://maps.app.goo.gl/x3FkKc5biUjXwQQT8" target="_blank"><img src="pics/360-icon.png" /></a>
                </div>
                <div id="frame-container">
                    <iframe id="frame" src="https://www.google.com/maps/embed?pb=!4v1735590297874!6m8!1m7!1sCAoSLEFGMVFpcE5zUVlMVXpFbUdTQ2ZvWDUtUE9LU2lCRnh6TXNDUkVPTU9PNE9j!2m2!1d45.45628204443516!2d6.900992633274518!3f27.433818663633062!4f4.213947521401124!5f0.7820865974627469" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>

    );
} 