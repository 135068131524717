import Home         from "./components/home/home";
import Admin        from "./components/admin/admin";
import AdminRental  from "./components/admin/rental";
import Footer       from "./components/footer/footer";
import  {Navigate, BrowserRouter, Routes, Route} from "react-router-dom";
import  './App.css';

function App() {
     
    const res = [];        
   
    res.push(
        <> 
        
        <header>             
            <div id="wood-planks">
            <div id="black-snow"></div>
            <div id="logo"></div>
            <div id="bottom-header"></div>
         </div>
        
        </header>
        <main>           
            <BrowserRouter>    
               <Routes>
                   <Route path="/"              element = {<Home />} />                                                 
                   <Route path="/admin"         element = {<Admin />} /> 
                   <Route path="/admin/rental"  element = {<AdminRental />} /> 
                   <Route path="*"              element = {<Navigate to="/" replace />} /> 
               </Routes>         
            </BrowserRouter>           
        </main>  
        <footer> 
            <Footer />
        </footer>
       
        </>
    );
     
    return res;
}

export default App;
